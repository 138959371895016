<template>
  <v-card max-width="380px" height="200px">
    <v-card-text>
      <v-chip :color="color" rounded="circle" size="large">
        <component :is="component"></component>
      </v-chip>
      <div class="mt-3">
        <h3 class="font-weight-bold mb-2">{{ title }}</h3>
        <span class="text-blue-grey-lighten-2">{{ description }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    component: { type: Object, required: true },
    color: { type: String, required: true },
  },
}
</script>

<style scoped lang="scss"></style>
