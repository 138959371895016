<template>
  <b-form
    :submit="{ title: 'Зберегти', handler: submit }"
    :reject="{
      title: 'Відхилити зміни',
      handler: () => localCompany.$reset(),
    }"
    :actions-disabled="
      !localCompany.$hasChanges || !userAccessRights.includes('company.update')
    ">
    <v-row>
      <v-col cols="12" md="12">
        <b-input
          v-model="localCompany.fullName"
          label="Повна назва згідно ЄДР"
          hide-details
          placeholder="Введіть текст"
          :error-messages="getErrorMessages(v$.fullName)"
          @blur="v$.fullName.$touch()"></b-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <b-input
          v-model="localCompany.shortName"
          label="Коротка назва"
          hide-details
          placeholder="Введіть текст"
          :error-messages="getErrorMessages(v$.shortName)"
          @blur="v$.shortName.$touch()"></b-input>
      </v-col>
      <v-col cols="12" md="4">
        <b-autocomplete
          v-model="localCompany.countryId"
          :items="[...(directory.K040 || [])].filter(d => !d.closeDate)"
          label="Країна"
          hide-details
          item-value="id"
          placeholder="Оберіть зі списку"
          :error-messages="getErrorMessages(v$.countryId)"
          @blur="v$.countryId.$touch()"></b-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <b-input
          v-model="localCompany.edrpou"
          label="ЄДРПОУ"
          hide-details
          mask="########"
          placeholder="Введіть ЄДРПОУ"
          :error-messages="getErrorMessages(v$.edrpou)"
          @blur="v$.edrpou.$touch()"></b-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <b-autocomplete
          v-model="localCompany.sectorEconomyId"
          :items="[...(directory.K070 || [])].filter(d => !d.closeDate)"
          label="Інституційний сектор економіки"
          hide-details
          item-value="id"
          placeholder="Оберіть зі списку"
          :error-messages="getErrorMessages(v$.sectorEconomyId)"
          @blur="v$.sectorEconomyId.$touch()"></b-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mb-10">
      <v-col cols="12" md="6">
        <b-select
          v-model="localCompany.typeId"
          :items="$directory.get('companyTypes', localCompany.type)"
          item-value="id"
          hide-details
          label="Тип"
          placeholder="Оберіть зі списку"
          :loading="$loading.isLoading('companyTypes')"
          :error-messages="getErrorMessages(v$.typeId)"
          @focus="$directory.fill('companyTypes')"
          @blur="v$.typeId.$touch()"></b-select>
      </v-col>
      <v-col cols="12" md="6">
        <b-autocomplete
          v-model="localCompany.typeEconomyActivityId"
          :items="[...(directory.K110 || [])].filter(d => !d.closeDate)"
          :item-disabled="d => d.closeDate"
          label="Вид економічної діяльності особи"
          hide-details
          item-value="id"
          placeholder="Оберіть зі списку"
          :error-messages="getErrorMessages(v$.typeEconomyActivityId)"
          @blur="v$.typeEconomyActivityId.$touch()"></b-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <b-autocomplete
          v-model="localCompany.NBUPersonTypeId"
          :items="[...(directory.F082 || [])].filter(d => !d.closeDate)"
          label="Тип особи НБУ"
          hide-details
          item-value="id"
          placeholder="Оберіть зі списку"
          :error-messages="getErrorMessages(v$.NBUPersonTypeId)"
          @blur="v$.NBUPersonTypeId.$touch()"></b-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <b-autocomplete
          v-model="localCompany.NBURegCodeTypeId"
          :items="[...(directory.K021 || [])].filter(d => !d.closeDate)"
          label="Тип ЄДРПОУ НБУ"
          hide-details
          item-value="id"
          placeholder="Оберіть зі списку"
          :error-messages="getErrorMessages(v$.NBURegCodeTypeId)"
          @blur="v$.NBURegCodeTypeId.$touch()"></b-autocomplete>
      </v-col>
    </v-row>
  </b-form>
</template>

<script lang="ts">
import { BForm } from 'best-modules/components'
import { getErrorMessages } from 'best-modules/utils'
import { computed, PropType } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { Company } from '@/utils/types'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { storeToRefs } from 'pinia'
import { useUser } from '@/store/user'
import { updateCompany } from '@/request/company'
import { minLength, required } from '@vuelidate/validators'
import { usePackageDirectory } from '@/store/package-directory'
import { useBreadcrumb } from '@/plugins/breadcrumb'

export default {
  name: 'GeneralInfo',
  components: { BForm },
  emits: ['update:company'],
  props: {
    company: {
      type: Object as PropType<CachedObjectProxy<Company>>,
      required: true,
    },
  },
  methods: { getErrorMessages },
  setup(props, { emit }) {
    const breadcrumb = useBreadcrumb()
    const localCompany = computed({
      get: () => props.company,
      set: val => emit('update:company', val),
    })
    const { userAccessRights } = storeToRefs(useUser())
    const { directory } = usePackageDirectory()

    const rules = {
      shortName: { required },
      fullName: { required },
      edrpou: { required, minLength: minLength(8) },
      typeId: { required },
      sectorEconomyId: { required },
      typeEconomyActivityId: { required },
      countryId: { required },
      NBUPersonTypeId: { required },
      NBURegCodeTypeId: { required },
    }

    const v$ = useVuelidate(rules, localCompany.value.$object)

    const submit = () => {
      v$.value.$touch()
      if (!v$.value.$invalid) {
        return updateCompany(localCompany.value.$object).then(res => {
          localCompany.value.$set(res)
          breadcrumb.set([
            {
              title: res.shortName,
              index: 1,
            },
          ])
        })
      }
    }

    return { localCompany, userAccessRights, v$, submit, directory }
  },
}
</script>

<style scoped lang="scss"></style>
